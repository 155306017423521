<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">部门列表</div>
    </el-header>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card>
          <div slot="header" class="clearfix">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>部门</div>
              <div v-if="canOperate">
                <el-button
                  size="small"
                  style="margin-right: 5px"
                  type="text"
                  @click="handleInitCreateDepartment"
                  >添加</el-button
                >
                <el-button
                  size="small"
                  style="margin-right: 5px"
                  type="text"
                  @click="handleInitModifyDepartment"
                  >编辑</el-button
                >
                <el-button
                  size="small"
                  style="margin-right: 5px; color: red"
                  type="text"
                  @click="handleDeleteDep"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
          <el-tree
            ref="treeRef"
            :data="departmentTree"
            :props="{
              children: 'children',
              label: 'departmentName',
            }"
            node-key="departmentId"
            :current-node-key="currentNodeKey"
            empty-text="暂无部门数据"
            highlight-current
            :expand-on-click-node="false"
            default-expand-all
            @node-click="handleNodeClick"
            v-if="currentNodeKey"
          >
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div
            slot="header"
            style="display: flex; justify-content: space-between"
          >
            <div>部门员工信息</div>
            <div>
              <el-button
                @click="handleInitAddDepartmentStaff"
                size="small"
                type="primary"
                v-if="canOperate"
                >添加部门员工</el-button
              >
            </div>
          </div>
          <el-table
            v-loading="tableLoading"
            :data="list"
            border
            style="width: 100%"
            ref="list"
            stripe
            size="small"
            empty-text="暂无员工数据"
          >
            <el-table-column prop="userName" label="用户姓名" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.userName }}
                  <el-tag size="mini" v-if="scope.row.hasDirector == 1"
                    >负责人</el-tag
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              label="用户手机号"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="departmentName"
              label="部门"
              align="center"
            ></el-table-column>

            <el-table-column label="操作" align="center" v-if="canOperate">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleCancel(scope.row)"
                  size="small"
                  v-if="scope.row.hasDirector == 1"
                >
                  取消负责人
                </el-button>
                <el-button
                  type="text"
                  @click="handleSet(scope.row)"
                  size="small"
                  v-if="scope.row.hasDirector !== 1"
                >
                  设为负责人
                </el-button>
                <el-tooltip placement="top" content="移出部门">
                  <el-button
                    type="text"
                    @click="handleRemoveStaffFromDep(scope.row)"
                    size="small"
                    style="color: red"
                  >
                    移出部门
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageVo.current"
              :page-sizes="[2, 10, 20, 50, 100]"
              :page-size="pageVo.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="添加部门用户" :visible.sync="dialogForAddDepartmentStaff">
      <el-form
        ref="addStaffFormRef"
        :model="addStaffForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="员工:" prop="staffId">
              <el-select
                style="width: 100%"
                v-model="addStaffForm.staffId"
                placeholder="请选择要添加到部门的员工"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.userName"
                ></el-option
              ></el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForAddDepartmentStaff = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleAddDepartmentStaff"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="添加部门" :visible.sync="dialogForCreateDepartment">
      <el-form
        ref="addDepartmentFormRef"
        :model="addDepartmentForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="部门名称:" prop="departmentName">
              <el-input
                v-model="addDepartmentForm.departmentName"
                placeholder="部门名称"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="负责人:" prop="directorUserId">
              <el-select
                v-model="addDepartmentForm.directorUserId"
                placeholder="请选择负责人"
                style="width: 100%"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.userName"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForCreateDepartment = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleCreateDepartment"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="编辑部门" :visible.sync="dialogForModifyDepartment">
      <el-form
        ref="modifyDepartmentFormRef"
        :model="modifyDepartmentForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="部门名称:" prop="departmentName">
              <el-input
                v-model="modifyDepartmentForm.departmentName"
                placeholder="部门名称"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="负责人:" prop="directorUserId">
              <el-select
                v-model="modifyDepartmentForm.directorUserId"
                placeholder="请选择负责人"
                style="width: 100%"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.userName"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifyDepartment = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleModifyDepartment"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  createDepartment,
  createDepartmentUserRa,
  deleteDepartment,
  deleteDepartmentUserRa,
  editUserOfMerchant,
  getDepartmentNoBindUserList,
  getDepartmentTreeList,
  getUserInfoListByDepartmentId,
  getUserListOfMerchantByPage,
  loadUserListOfMerchantByPage,
  manageDirectorDepartment,
  updateDepartment,
  updateDirectorDepartmentOfUser,
} from "../../api";

export default {
  name: "",
  data() {
    return {
      allUserList: [],
      modifyDepartmentForm: {
        departmentName: "",
        parentDepartmentId: "",
        directorUserId: "",
        userId: "",
      },
      addDepartmentForm: {
        departmentName: "",
        parentDepartmentId: "",
        directorUserId: "",
        userId: "",
      },
      addStaffForm: {
        departmentId: "",
        userId: "",
        staffId: "",
      },
      userList: [],
      dialogForCreateDepartment: false,
      dialogForModifyDepartment: false,
      dialogForAddDepartmentStaff: false,
      tableLoading: false,
      canOperate: false,
      defaultCheckedDeparmentId: [],
      departmentTree: [],
      userId: this.$store.getters.userId,
      currentNodeKey: "",
      currentDepartment: {},
      searchForm: {
        userName: null,
        phone: null,
      },
      modifyForm: {
        userId: "",
        userName: "",
        password: "",
        phone: "",
        manageUserId: this.$store.getters.userId,
      },
      list: [],
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
      rules: {
        departmentName: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
        ],
        directorUserId: [
          { required: true, message: "请选择负责人", trigger: "change" },
        ],
        staffId: [{ required: true, message: "请选择员工", trigger: "change" }],
      },
    };
  },
  created() {
    this.pageInit();
  },
  mounted() {},
  methods: {
    async handleSet(row) {
      this.$confirm(`确认将${row.userName}设为部门负责人吗?`, "设为负责人", {
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonText: "确认",
      })
        .then(() => {
          const param = {
            departmentUserRaId: row.departmentUserRaId,
            userId: this.userId,
            hasDirector: 1,
          };
          updateDirectorDepartmentOfUser(param).then(() => {
            this.$notify({
              type: "success",
              title: "成功",
              message: "设置成功",
            });
            this.loadDepartmentStaffInfo();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async handleCancel(row) {
      this.$confirm(`确认取消${row.userName}部门负责人身份吗?`, "取消负责人", {
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonText: "确认",
      })
        .then(() => {
          const param = {
            departmentUserRaId: row.departmentUserRaId,
            userId: this.userId,
            hasDirector: 0,
          };
          updateDirectorDepartmentOfUser(param).then(() => {
            this.$notify({
              type: "success",
              title: "成功",
              message: "设置成功",
            });
            this.loadDepartmentStaffInfo();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleCreateDepartment() {
      this.$refs.addDepartmentFormRef.validate((valid) => {
        if (valid) {
          createDepartment({ ...this.addDepartmentForm }).then(() => {
            this.$message.success("添加部门成功");
            this.pageInit();
            this.dialogForCreateDepartment = false;
          });
        }
      });
    },
    async handleInitAddDepartmentStaff() {
      await this.loadDepartmentNoBindUserList();
      this.addStaffForm.departmentId = this.currentNodeKey;
      this.dialogForAddDepartmentStaff = true;
    },
    handleAddDepartmentStaff() {
      this.$refs.addStaffFormRef.validate((valid) => {
        if (valid) {
          createDepartmentUserRa({ ...this.addStaffForm }).then((data) => {
            if (data) {
              this.$message.success("添加部门员工成功");
              this.loadDepartmentStaffInfo();
              this.dialogForAddDepartmentStaff = false;
            }
          });
        }
      });
    },
    async loadAllStaff() {
      let res = await getUserListOfMerchantByPage({
        current: 1,
        size: 500,
        params: { userId: this.userId },
      });
      this.allUserList = res.records;
    },
    async loadDepartmentNoBindUserList() {
      this.userList = await getDepartmentNoBindUserList({
        userId: this.userId,
        departmentId: this.currentNodeKey,
      });
    },
    async handleInitCreateDepartment() {
      await this.loadAllStaff();
      this.addDepartmentForm.parentDepartmentId = this.currentNodeKey;
      this.addDepartmentForm.userId = this.userId;
      this.dialogForCreateDepartment = true;
    },
    async handleInitModifyDepartment() {
      console.log("this.currentNodeKey=======", this.departmentTree);
      if (this.currentNodeKey) {
        this.modifyDepartmentForm.userId = this.userId;
        this.modifyDepartmentForm.departmentName =
          this.currentDepartment.departmentName;
        this.modifyDepartmentForm.departmentId =
          this.currentDepartment.departmentId;
        this.modifyDepartmentForm.directorUserId =
          this.currentDepartment.directorUserId;
        await this.loadAllStaff();
        this.dialogForModifyDepartment = true;
      }
    },
    handleModifyDepartment() {
      this.$refs.modifyDepartmentFormRef.validate((valid) => {
        if (valid) {
          updateDepartment({ ...this.modifyDepartmentForm }).then(() => {
            this.$message.success("编辑部门成功");
            this.pageInit();
            this.dialogForModifyDepartment = false;
          });
        }
      });
    },
    async loadDepartmentStaffInfo(departmentId) {
      this.tableLoading = true;
      const param = {
        params: {
          userId: this.userId,
          departmentId: this.currentNodeKey,
        },
        ...this.pageVo,
      };
      const data = await getUserInfoListByDepartmentId(param);
      this.list = data.records;
      this.totalCount = data.total;
      this.tableLoading = false;
    },
    async handleNodeClick(p) {
      if (this.currentNodeKey !== p.departmentId) {
        this.currentNodeKey = p.departmentId;
        this.currentDepartment = p;
        await this.onSubmit();
      }
    },
    async pageInit() {
      this.loading = true;
      //判断有无部门权限
      this.canOperate = await manageDirectorDepartment({ userId: this.userId });
      this.departmentTree = await getDepartmentTreeList({
        userId: this.userId,
      });
      if (this.departmentTree.length) {
        this.currentNodeKey = this.departmentTree[0].departmentId.toString();
        this.currentDepartment = this.departmentTree[0];
        //加载部门员工信息
        await this.loadDepartmentStaffInfo();
        this.loading = false;
      } else {
        this.departmentTree = [];
        this.list = [];
        this.loading = false;
      }
    },
    handleInitModifyUser(row) {
      Object.assign(this.modifyForm, row);
      this.modifyForm.password = null;
      this.dialogForModifyUser = true;
    },
    handleModifyUser() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.modifyForm,
          };
          editUserOfMerchant(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "编辑用户成功",
              });
              this.dialogForModifyUser = false;
              this.loadUserList();
            }
          });
        }
      });
    },
    onSubmit() {
      this.pageVo.current = 1;
      this.loadDepartmentStaffInfo();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadDepartmentStaffInfo();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadDepartmentStaffInfo();
    },
    handleDeleteDep() {
      if (this.currentNodeKey) {
        this.$confirm(
          `确认删除部门${this.currentDepartment.departmentName}吗?`,
          "删除部门",
          {
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "确认",
          }
        )
          .then(() => {
            deleteDepartment({
              departmentId: this.currentNodeKey,
              userId: this.userId,
            }).then((data) => {
              if (data) {
                this.$message.success("删除部门成功");
                this.pageInit();
              }
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    handleRemoveStaffFromDep(row) {
      if (row.userId) {
        this.$confirm(`确认从部门移出员工${row.userName}吗?`, "移出员工", {
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonText: "确认",
        })
          .then(() => {
            console.log("then");
            deleteDepartmentUserRa({
              departmentUserRaId: row.departmentUserRaId,
            }).then(() => {
              this.$message.success("移出员工成功！");
              this.loadDepartmentStaffInfo();
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style lang="scss">
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>
